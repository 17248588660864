import React from "react";

export default function Star({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="1278.000000pt"
      height="1280.000000pt"
      viewBox="0 0 1278.000000 1280.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
        <path d="M6760 12443 c-137 -26 -302 -163 -453 -375 -207 -293 -384 -645 -802 -1598 -347 -790 -486 -1070 -667 -1337 -211 -311 -357 -373 -878 -374 -303 0 -573 22 -1315 106 -310 36 -666 73 -930 97 -191 17 -792 17 -905 0 -359 -56 -525 -174 -538 -382 -7 -128 43 -265 161 -442 197 -294 514 -612 1317 -1323 955 -845 1247 -1174 1290 -1452 37 -234 -95 -656 -453 -1458 -364 -816 -430 -963 -490 -1110 -252 -611 -352 -998 -318 -1236 31 -222 145 -333 357 -346 311 -21 768 169 1699 704 749 431 885 508 1051 596 451 240 718 338 924 341 121 1 161 -10 310 -84 265 -133 574 -380 1300 -1040 1006 -916 1405 -1206 1752 -1276 102 -21 173 -13 255 27 103 50 160 135 204 304 21 81 23 111 23 315 0 125 -5 267 -12 320 -51 379 -107 674 -253 1335 -229 1034 -279 1327 -279 1647 0 162 16 260 55 346 101 221 462 490 1275 952 661 375 831 473 1005 578 739 446 1065 761 1065 1027 0 155 -96 273 -306 378 -300 150 -748 236 -1764 342 -1052 108 -1334 148 -1637 225 -387 100 -514 201 -648 515 -117 276 -211 629 -391 1482 -135 644 -212 973 -289 1237 -115 398 -240 668 -380 824 -94 105 -221 156 -335 135z" />
      </g>
    </svg>
  );
}
